import { defineStore } from "pinia";
import { format } from "date-fns";
import { getUpcomingWeekNumbers } from "../helpers/functions";
import { router } from "../router";

import { useMemberStore } from "./MemberStore";
import { useLocationStore } from "./LocationStore";
import { useEventStore } from "./EventStore";

import { SHA256 } from "crypto-js";

import axios from "axios";

const baseUrl = `${import.meta.env.VITE_AUTH_URL}/users`;

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: null,
    refreshTokenTimeout: null,
    // bookableWeeks: getUpcomingWeekNumbers(8),
    sortedTimeslotsUpdateTracker: 0,
    loginMessage: "",
    loginError: "",
    hideUnavailableTimeslots: true,
    calendarView: "listDay",
    hideAdminMenu: false,
    refreshCalendar: false,
    // user: JSON.parse(localStorage.getItem('user'))
  }),
  getters: {
    isAdmin(state) {
      if (state.user) {
        return state.user.usertype === "admin"; // Use '===' for strict equality comparison
      } else {
        return false;
      }
    },
    userLevel(state) {
      if (state.user) {
        if (state.user.usertype === "admin") {
          return 3;
        } else if (
          state.user.usertype === "support" ||
          state.user.usertype === "trainer"
        ) {
          return 2;
        } else {
          return 1;
        }
      }
    },
    bookableWeeks() {
      return getUpcomingWeekNumbers(8);
    },
    sortedTimeslots(state) {
      if (!state.user?.timeslots) {
        return { past: [], upcoming: [] };
      }

      // Function to get the week number
      const getWeekNumber = (date) => {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
      };

      const now = new Date();
      const currentWeekNumber = getWeekNumber(now);
      const past = [];
      const upcoming = [];

      state.user.timeslots.forEach((timeslot) => {
        const date = new Date(timeslot.start);
        const weekNumber = getWeekNumber(date);

        if (weekNumber < currentWeekNumber) {
          past.push(timeslot);
        } else {
          upcoming.push(timeslot);
        }
      });

      // Sort the arrays
      past.sort((a, b) => new Date(a.start) - new Date(b.start));
      upcoming.sort((a, b) => new Date(a.start) - new Date(b.start));

      this.sortedTimeslotsUpdateTracker++;

      return { past, upcoming };
    },
    sortedTimeslotsCount(state) {
      const sortedTimeslots = state.sortedTimeslots;
      return {
        // This way, even if there are more than 8 upcoming timeslots, only the first 8 will be taken into account.
        upcoming: sortedTimeslots.upcoming.slice(0, 8).length,
        past: sortedTimeslots.past.length,
      };
    },
    combinedWeeksAndTrainings(state) {
      if (state.user && state.user.usertype !== "trainer") {
        // Access lastUpdate to create a dependency
        console.log("Update tracker:", state.sortedTimeslotsUpdateTracker); // log the tracker to create a dependency

        let combinedArray = [];

        state.bookableWeeks.forEach((weekObject) => {
          // Create a new week object that is a copy of the original
          let newWeekObject = { ...weekObject };
          let matchingTrainings = state.sortedTimeslots.upcoming.filter(
            (training) => {
              return training.extendedProps.week == newWeekObject.week;
            }
          );
          if (matchingTrainings && matchingTrainings.length > 0) {
            // Set the training property on the new week object to an array of trainings
            newWeekObject.trainings = matchingTrainings;
          }

          combinedArray.push(newWeekObject);
        });

        return combinedArray;
      } else {
        return [];
      }
    },
    inactiveDates(state) {
      const dates = [];
      if (state.user && state.user.inactive) {
        const startDate = new Date(state.user.inactive_from);
        const endDate = new Date(state.user.inactive_until);

        let currentDate = new Date(startDate); // Use this to loop through the dates

        while (currentDate <= endDate) {
          dates.push(format(currentDate, "yyyy-MM-dd")); // Add the formatted date to the array
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
      return dates;
    },
    altUserTypeDates(state) {
      const dates = [];
      if (state.user && state.user.alt_usertype) {
        const startDate = new Date(state.user.alt_usertype_from);
        const endDate = new Date(state.user.alt_usertype_until);

        let currentDate = new Date(startDate); // Use this to loop through the dates

        while (currentDate <= endDate) {
          dates.push(format(currentDate, "yyyy-MM-dd")); // Add the formatted date to the array
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
      return dates;
    },

    // combinedWeeksAndTrainingsFiltered(state) {
    //     const combinedArray = state.combinedWeeksAndTrainings;
    //     return combinedArray.filter((item) => typeof item !== 'object');
    // }
  },
  actions: {
    async computeHash(data) {
      const hashAlgorithm = "SHA-256";
      const hashBuffer = await crypto.subtle.digest(hashAlgorithm, data);

      // Converteer de hashwaarde naar een hexadecimale string
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
    },
    triggerCalendarRefresh() {
      this.refreshCalendar = !this.refreshCalendar;
    },
    async login(username, password) {
      try {
        // Input validation
        if (!username || !password) {
          throw new Error("Email en password zijn verplicht");
        }
        //alert('get Salt');
        const salt = await axios.post(
          `${import.meta.env.VITE_API_URL}salt.php`,
          { email: username }
        );
        //  const  salt = 'ccaa2402b11be0efebdbb7c80f5adc46';
        //   alert(salt);
        // Combineer het wachtwoord en de zoutwaarde
        const combinedString = password + salt.data;

        // Converteer de gecombineerde string naar een ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(combinedString);

        // Maak een hashfunctie met SHA-256

        const hashHex = await this.computeHash(data);
        // Bereken de hashwaarde

        const user = await axios.post(
          `${import.meta.env.VITE_API_URL}token.php`,
          { email: username, password: hashHex }
        );
        //console.log(user);
        if (user.data.error) {
          //alert(user.data.error);
          this.loginError = user.data.error;
          //throw new Error(user.data.error);
        }
        if (user.data.token) {
          const memberStore = useMemberStore();
          const locationStore = useLocationStore();
          // console.log("USER DATA", user.data);
          this.user = user.data;

          locationStore.locations = user.data.locations;

          if (this.user.usertype == "support") {
            this.hideUnavailableTimeslots = false;
            this.calendarView = "timeGridWeek";
            if (Object.keys(user.data.locations).length == 1) {
              const firstLocation = Object.values(user.data.locations)[0];
              if (firstLocation) {
                locationStore.setCurrentLocation(firstLocation.id);
              }
            }
          }
          if (this.user.usertype == "trainer") {
            this.hideUnavailableTimeslots = false;
            this.calendarView = "timeGridDay";
            if (Object.keys(user.data.locations).length == 1) {
              const firstLocation = Object.values(user.data.locations)[0];
              if (firstLocation) {
                locationStore.setCurrentLocation(firstLocation.id);
              }
            }
          }

          if (this.user.usertype == "admin") {
            this.hideUnavailableTimeslots = false;
            this.calendarView = "timeGridWeek";
            router.push("/locations");
          } else if (this.user.usertype == "support") {
            // Parse the location_id string as JSON to get the array
            var locationArray = JSON.parse(this.user.location_id);
            // Access the first element and ensure it's a number
            var locationId = Number(locationArray[0]);
            // Use the locationId in the router push
            router.push(`/location/${locationId}/calendar`);
          } else if (this.user.usertype == "trainer") {
            // Parse the location_id string as JSON to get the array
            var locationArray = JSON.parse(this.user.location_id);
            // Access the first element and ensure it's a number
            var locationId = Number(locationArray[0]);
            // Use the locationId in the router push
            router.push(`/location/${locationId}/calendar`);
          } else {
            this.refetchUser();
            router.push("/");
          }
          return;
        } else {
          this.user = null;
          router.push("/login");
        }
        //throw new Error(user.data.error);
        // Encrypt the password before sending
        // const encryptedPassword = SHA256(password).toString();
        // console.log(username, password);
        //const encodedPassword = encodeURIComponent(password);
      } catch (error) {
        console.error("Failed to log in:", error);
      }
      // const user = await axios.post(`${import.meta.env.VITE_API_URL}token.php?email=${username}&password=${password}`)

      // // Temporary because I don't error codes it's all 200..
      // if(user.data.token) {
      //     const memberStore = useMemberStore()
      //     const locationStore = useLocationStore()
      //     this.user = user.data;
      //     // this.user.usertype = 'admin';
      //     // Temporary hack to login as admin
      //     // if(this.user.usertype == 'admin') {
      //     //     console.log('Admin --> Get all locations and members')
      //     //     try {
      //     //         await locationStore.fetchLocations();
      //     //         await memberStore.fetchMembers();
      //     //         router.push('/dashboard');
      //     //     } catch(error) {
      //     //         console.error('Failed to fetch data:', error);
      //     //     }
      //     // }
      //     router.push('/');
      // } else {
      //     this.user = null;
      //     router.push('/login');
      // }
    },
    logout() {
      console.log("Log out called");

      // localStorage.removeItem('user');
      // this.stopRefreshTokenTimer();

      const locationStore = useLocationStore();
      const memberStore = useMemberStore();

      // Clear Pinia store state
      this.$reset(); // authStore
      memberStore.$reset();
      locationStore.$reset();

      // Clear localStorage
      localStorage.clear();
      sessionStorage.clear();

      router.push("/login");
    },
    async refreshToken() {
      // const response = await fetchWrapper.post(`${baseUrl}/refresh-token`, {}, { credentials: 'include' });
      console.log("Refreshing token");
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}check-token.php?token=${
          this.user.token
        }`
      );

      if (!response.data.token) {
        this.logout();
      }
      this.startRefreshTokenTimer();
    },
    startRefreshTokenTimer() {
      // parse json object from base64 encoded jwt token
      const jwtBase64 = this.user.jwtToken.split(".")[1];
      const jwtToken = JSON.parse(atob(jwtBase64));

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      console.log(expires);
      const timeout = expires.getTime() - Date.now() - 60 * 1000;
      console.log(timeout);
      this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    },
    stopRefreshTokenTimer() {
      clearTimeout(this.refreshTokenTimeout);
    },
    verifyUser() {
      //Verifying if token exists
      console.log("Verifying user token");
      if (!this.user?.token) {
        this.logout();
      }
    },
    async refetchUser() {
      try {
        // console.log(
        //   "refetch: " +
        //     import.meta.env.VITE_API_URL +
        //     "load-user.php?user_id=" +
        //     this.user.id
        // );
        const user_id = this.user.id;
        const token = this.user.token;
        // const response = await axios.get(`${import.meta.env.VITE_API_URL}load-user.php?user_id=${this.user.id}`);
        const response = await axios.get(
          `${
            import.meta.env.VITE_API_URL
          }load-user.php?user_id=${user_id}&token=${token}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              //   'X-Access-User': this.user.id
            },
          }
        );
        // console.log(response);
        this.isLoading = true;
        this.error = null;
        const jwtToken = this.user.token;
        if (jwtToken === response.data.token) {
          this.user = response.data;
        } else {
          this.logout();
        }

        // this.user = response.data;
      } catch (error) {
        this.error = error.message;
        this.logout();
      } finally {
        this.loading = false;
      }
    },
    async resetPassword(email) {
      console.log("Password reset request for user " + email);
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}reset-password.php?email=${email}`
        );
        console.log(response.data); // Log the response data
      } catch (error) {
        console.error("An error occurred:", error); // Log the error
        // Customize the error message based on the error type
        if (error.response) {
          // The request was made and the server responded with a status code
          error.message = error.response.data.error;
        }
        // console.log(error.config);
        throw error; // Throw the error again
      }
    },
    async generateRandomHex(length) {
      const bytes = new Uint8Array(length);
      crypto.getRandomValues(bytes);
      let hex = "";
      for (let i = 0; i < length; i++) {
        hex += bytes[i].toString(16).padStart(2, "0");
      }
      return hex;
    },
    async verifyToken(token) {
      try {
        // console.log("Token being sent for verification:", token);
        const response = await axios.post(
          `${import.meta.env.VITE_API_URL}verify-password-token.php`,
          { token: token }
        );
        console.log("Token verification response:", response.data);
        return response.data.valid;
      } catch (error) {
        console.error("Error verifying token:", error);
        return false;
      }
    },
    async updatePassword(token, password) {
      try {
        const isValid = await this.verifyToken(token);
        
        if (!isValid) {
          throw new Error("Token is expired or invalid");
        }
    
        const salt = await this.generateRandomHex(16);
        const combinedString = password + salt;
        const encoder = new TextEncoder();
        const data = encoder.encode(combinedString);
        const hashHex = await this.computeHash(data);
    
        // console.log("Sending request with data:", { token: token.substring(0, 10) + '...', password: '********', salt });
    
        const response = await axios.post(
          `${import.meta.env.VITE_API_URL}update-password.php`,
          { token, password: hashHex, salt }
        );
    
        // console.log("Password update response:", response.data);
    
        if (response.data.success) {
          router.push('/login');
          return { success: true, message: "Password updated successfully" };
        } else {
          // If the server sends an error message, throw it as an error
          throw new Error(response.data.error || "Failed to update password");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Server responded with error:", error.response.data);
          return { 
            success: false, 
            message: error.response.data.error || "An error occurred on the server",
            reason: error.response.data.reason || "Unknown reason"
          };
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received from server");
          return { success: false, message: "No response received from server" };
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error setting up request:", error.message);
          return { success: false, message: error.message };
        }
      }
    },
    async icalFeed() {
      console.log("Ical feed called");
      var icalStatus = true;
      if (this.user.ical_url) {
        icalStatus = false;
      }
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_URL}update-user.php?user_id=${
            this.user.id
          }&location_id=1&ical=${icalStatus}`,
          {
            headers: {
              Authorization: `Bearer ${this.user.token}`,
            },
          }
        );
        // this.user = response.data; Don't want to change all because you only receive updated properties.
        for (let prop in response.data) {
          if (prop in this.user) {
            this.user[prop] = response.data[prop];
          }
        }
        console.log(response.data); // Log the response data
      } catch (error) {
        console.error("An error occurred:", error); // Log the error
      }
    },
  },
  persist: true,
});
