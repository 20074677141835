<script setup>
import { ref, watch, onMounted, onBeforeMount, onUnmounted, nextTick, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore, useMemberStore, useLocationStore, useModalStore, useEventStore } from '../store';
import Modal from '../components/Modal.vue'
import MemberSelect from '../components/MemberSelect.vue';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import nlLocale from '@fullcalendar/core/locales/nl'
import 'add-to-calendar-button'
import { format } from 'date-fns'
// import { nl } from 'date-fns/locale'
import moment from 'moment'
import 'moment/dist/locale/nl'
import { detectMobile, getFirstDayOfWeek, capitalizeFirstLetter, getCurrentWeek, getCurrentYear, isAtLeast1HourAway, debounce } from '../helpers/functions'
// import { device } from "../main";

const authStore = useAuthStore()
const memberStore = useMemberStore()
const locationStore = useLocationStore()
const modalStore = useModalStore()
const eventStore = useEventStore()

const route = useRoute();
const router = useRouter();

const fullCalendar = ref(null)
const showLoader = ref(false)
const width = ref(document.documentElement.clientWidth)
const height = ref(document.documentElement.clientHeight)
const windowWidth = ref(window.innerWidth)
const refreshCountdown = ref(15)
const countdownInterval = ref(null)
const activeDay = ref(null)

const headerToolbarDesktop = {
  left: 'today prev,next',
  center: 'title',
  right: 'timeGridWeek,timeGridDay'
}

const headerToolbarAdminMobile = {
  left: 'today prev,next',
  center: 'title',
  right: ''
}

const headerToolbarMobile = {
  left: 'prev',
  center: 'title',
  right: 'next'
}

const calendarHeight = window.innerHeight - 200

const isRefreshEnabled = computed(() =>
  ['admin', 'support', 'trainer'].includes(authStore.user.usertype)
)

const handleEventClick = (clickInfo) => {
  eventStore.shiftEventAlert = "";
  eventStore.shiftEventStatus = false;
  eventStore.shiftEventMessage = "";
  const event = clickInfo.event || clickInfo;

  const extendedProps = event.extendedProps || {};
  const eventMembers = extendedProps.users || [];
  const eventStatus = extendedProps.status;

  const userId = memberStore.selectedMember && Object.keys(memberStore.selectedMember).length > 0
    ? memberStore.selectedMember.id
    : authStore.user.id;

  console.log('HandleEventClick checkpoint');
  if ([3, 4, 5, 6, 7, 8, 9, 10, 99].includes(eventStatus)) {
    if (authStore.userLevel > 1) {
      eventStore.recurringCount = 1;
      eventStore.selectEvent(clickInfo);
    } else {
      console.log('Clicked fully booked timeslot --> No action');
    }
  } else {
    eventStore.recurringCount = 1;
    eventStore.selectEvent(clickInfo);
  }

  // SHIFTING EVENT
  if (memberStore.selectedMember !== null && memberStore.selectedMember !== undefined) {
    const week = event.extendedProps.week;
    const weekNumber = week;
    const matchingWeek = memberStore.combinedWeeksAndTrainings.find(
      (weekObject) => weekObject.week == weekNumber
    );

    if (matchingWeek && Array.isArray(matchingWeek.trainings) && matchingWeek.trainings.length > 0) {
      const trainingStart = matchingWeek.trainings[0].start;
      if (trainingStart + "+02:00" == event.startStr) {
        return false;
      }
      eventStore.shiftEventAlert = " heeft deze week al een training";
      eventStore.shiftEventStatus = true;
      eventStore.shiftEventMessage = trainingStart;
    }
  }
}

const handleDatesSet = (dateInfo) => {
  const currentDate = dateInfo.start;
  const newWeek = getCurrentWeek(currentDate);
  const newYear = getCurrentYear(currentDate);

  // Only update if the week or year has changed
  if (route.query.week !== newWeek.toString() || route.query.year !== newYear.toString()) {
    router.push({
      path: route.path,
      query: {
        ...route.query,
        week: newWeek,
        year: newYear
      }
    });
  }
  checkWeekAvailability();

};

const calendarOptions = ref({
  locale: nlLocale,
  timeZone: 'Europe/Amsterdam',
  plugins: [bootstrap5Plugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
  themeSystem: 'bootstrap5',
  headerToolbar: headerToolbarDesktop,
  initialView: authStore.calendarView || "timeGridWeek",
  contentHeight: calendarHeight,
  slotDuration: { "minutes": 10 },
  slotLabelFormat: { hour: 'numeric', minute: '2-digit', omitZeroMinute: false },
  slotLabelInterval: { "minutes": 20 },
  slotMinTime: '06:40:00',
  slotMaxTime: '22:00:00',
  firstDay: 1,
  stickyHeaderDates: true,
  nowIndicator: true,
  scrollTime: new Date().toISOString().split('T')[1],
  dayMaxEvents: false,
  editable: false,
  selectable: true,
  weekNumbers: true,
  weekends: true,
  allDaySlot: false,
  buttonText: {
    today: 'Vandaag',
    month: 'Maand',
    week: 'Week',
    day: 'Dag',
    list: 'Lijst',
    prev: 'Vorige',
    next: 'Volgende'
  },
  lazyFetching: false,
  events: (info, successCallback, failureCallback) => {
    const start = info.startStr;
    const end = info.endStr;
    fetchEvents(1, 400, start, end)
      .then(events => {
        successCallback(events);
      })
      .catch(error => {
        console.error("Error fetching events:", error);
        failureCallback(error);
      });
  },
  eventClick: handleEventClick,
  // eventDidMount: handleEventDidMount,
  datesSet: handleDatesSet,
  loading: (isLoading) => {
    if(isLoading){
      showLoader.value = true;
    }else{
      showLoader.value = false;
    }
    if (!isLoading && memberStore.selectedMember) {
      
      debouncedScrollToSelectedMemberEvent();
    }
  },
  eventAfterAllRender: (view) => {
    showLoading.value = false;
  },
})

const checkWindowSize = () => {
  windowWidth.value = window.innerWidth;
}

const fetchEvents = async (page, limit, start, end) => {
  console.log('fetchEvents', page, limit, start, end);
  try {
    if (!locationStore.selectedLocation?.id) {
      locationStore.setCurrentLocation(route.params.id);
    }
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}load-timeslots.php?page=${page}&limit=${limit}&location=${locationStore.selectedLocation.id}&start=${start}&end=${end}`
    );
    if (response.ok) {
      const data = await response.json();
      console.log('Fetched data size:', JSON.stringify(data).length);
      return data;
    } else {
      console.error("Fout bij ophalen van evenementen");
      return [];
    }
  } catch (error) {
    console.error("Fout bij ophalen van evenementen", error);
    return [];
  }
}

const loadEvents = async (start, end) => {
  console.log('loadEvents', start, end);
  isLoading.value = true;

  let page = 1;
  let limit = 400;
  // await locationStore.fetchLocationMembers();
  eventStore.events.length = 0;
  eventStore.loader.length = 0;
  eventStore.start = start;
  eventStore.end = end;
  while (true) {
    const eventData = await fetchEvents(page, limit, start, end);
    if (eventData.length === 0) {
      break;
    }
    eventStore.loader.push(...eventData);
    page++;
  }
  eventStore.events.push(...eventStore.loader);
  isLoading.value = false;
}

const checkWeekAvailability = () => {
  if (!fullCalendar.value) return;

  const calendarApi = fullCalendar.value.getApi();
  const currentDate = calendarApi.getDate();
  const currentWeekNumber = getCurrentWeek(currentDate);
  const todaysWeekNumber = getCurrentWeek(new Date());

  const isWeekBeyondRange = currentWeekNumber > todaysWeekNumber + 7;

  if (isWeekBeyondRange) {
    const element = document.querySelector('.fc-view-harness');
    let alert = document.querySelector('.fc-view-harness .disabledWeek');

    if (!alert) {
      alert = document.createElement('span');
      alert.className = 'alert alert-warning disabledWeek';
      element.appendChild(alert);
    }

    alert.textContent = 'Week nog niet beschikbaar om te boeken. Maximaal 8 weken vooruit boeken.';
  } else {
    const alert = document.querySelector('.fc-view-harness .disabledWeek');
    if (alert) {
      alert.remove();
    }
  }
};

const updateCountdown = () => {
  refreshCountdown.value--;
  if (refreshCountdown.value <= 0) {
    refreshCountdown.value = 15;
    refreshCalendar();
  }
}

// const openModal = () => {
//   modalStore.showModal();
// }

// const calculateCalendarHeight = () => {
//   return window.innerHeight - 100;
// }

const goToDate = (date) => {
  const formattedDate = format(new Date(date), 'yyyy-MM-dd');
  activeDay.value = formattedDate;
  if (fullCalendar.value) {
    return fullCalendar.value.getApi().gotoDate(formattedDate);
  }
}

const onResize = ({ width, height }) => {
  nextTick(() => {
    console.log(fullCalendar.value.getApi());
    if (width > 600) {
      console.log('desktop');
      calendarOptions.value.headerToolbar = headerToolbarDesktop;
      fullCalendar.value.getApi().changeView('timeGridWeek');
    } else {
      console.log('mobile');
      calendarOptions.value.headerToolbar = headerToolbarMobile;
      fullCalendar.value.getApi().changeView('listWeek');
    }
    console.log(`New width: ${width}, new height: ${height}`);
  });
}

// const handleWeekendsToggle = () => {
//   calendarOptions.value.weekends = !calendarOptions.value.weekends;
// }

// const formattedStartDateTime = (value) => {
//   console.log('formattedStartDateTime Value:', value);
//   const timePart = value.split('T')[1];
//   const [hours, minutes] = timePart.split(':');
//   return `${hours}:${minutes}`;
// }

const removeEventMember = async (eventInfo, memberKey) => {
  console.log('removeUser ---> ', eventInfo);
  const eventMember = eventStore.eventInfo.members[memberKey];
  memberStore.selectedMember = eventMember;

  eventStore.updateEvent().then((result) => {
    console.log('updateEvent finished with result:', result);

    eventStore.events.length = 0;
    // Dit is dubbel op met de refetchEvents in loadEvents??
    // loadEvents(eventStore.start, eventStore.end);

    fullCalendar.value.getApi().refetchEvents();

    modalStore.setModalVisible('selectEvent', false);
  });
}

const showEventNote = async (eventInfo, memberKey) => {
  console.log('removeUser ---> ' + eventInfo);
  const eventMember = eventStore.eventInfo.members[memberKey];
  memberStore.selectedMember = eventMember;

  modalStore.setModalVisible('eventNote' + eventMember.id, true);
}

const addEventNote = async () => {
  const note = {
    date: moment().format('YYYY-MM-DD\THH:mm:ss'),
    timeslot: eventStore.eventInfo.event.extendedProps.dbID,
    message: memberStore.selectedMember.addNote
  };
  memberStore.selectedMember.addNote = '';
  memberStore.selectedMember.addEventNote = note;
  memberStore.updateMember(memberStore.selectedMember);
}

const bookEvent = () => {
  eventStore.updateEvent().then((result) => {
    console.log('updateEvent finished with result:', result);
    if (!authStore.isAdmin) {
      //memberStore.selectedMember = {};
    }

    if (!memberStore.isKmtMemberValid) {
      locationStore.fetchLocationMembers();
    }

    memberStore.kmt.showDuoForm = false;
    memberStore.kmt.memberInfo = {};
    memberStore.kmt.duoInfo = {};

    eventStore.events.length = 0;
    // Dit is dubbel op met de refetchEvents in loadEvents??
    // loadEvents(eventStore.start, eventStore.end);

    fullCalendar.value.getApi().refetchEvents();
  }).catch((error) => {
    console.log('updateEvent failed with error:', error);
  });
}

const extraBookEvent = () => {
  eventStore.extra = true;
  bookEvent();
  eventStore.extra = false;
}

const bookShiftEvent = () => {
  eventStore.shift = true;
  bookEvent();
  eventStore.shift = false;
}

const formattedStartDate = (value) => {
  const [datePart, timePart] = value.split('T');
  const [year, month, day] = datePart.split('-').map(num => parseInt(num, 10));
  const [hours, minutes] = timePart.split(':').map(num => parseInt(num, 10));

  const dayNames = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
  const monthNames = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

  const dateObj = new Date(Date.UTC(year, month - 1, day, hours, minutes));
  const dayName = dayNames[dateObj.getUTCDay()];
  const formattedDate = `${day} ${monthNames[month - 1]} ${year} om ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

  return dayName + ' ' + formattedDate;
}

// const handleEvents = (events) => {
//   currentEvents.value = events;
// }

// const toggleView = () => {
//   const viewMode = fullCalendar.value.getApi().currentData.currentViewType;
//   console.log(viewMode);
//   if (viewMode == 'dayGridMonth') {
//     fullCalendar.value.getApi().changeView('listWeek');
//   } else {
//     fullCalendar.value.getApi().changeView('dayGridMonth');
//   }
// }

const eventContent = (args) => {
  console.log('eventContent', args);
  let italicEl = document.createElement('i');

  if (args.event.extendedProps.isUrgent) {
    italicEl.innerHTML = 'urgent event';
  } else {
    italicEl.innerHTML = 'normal event';
  }

  let arrayOfDomNodes = [italicEl];
  return { domNodes: arrayOfDomNodes };
}

// const handleViewChange = (arg) => {
//   console.log('handleViewChange', arg);
//   authStore.calendarView = arg.view.type;
// }

const refreshCalendar = () => {
  console.log('Refresh calendar');
  const fullCalendarApi = fullCalendar.value.getApi();

  // Get the current view's start and end dates
  const view = fullCalendarApi.view;
  const start = view.activeStart.toISOString();
  const end = view.activeEnd.toISOString();

  // Clear existing events
  eventStore.events.length = 0;

  // Refetch events for the current view
  loadEvents(start, end).then(() => {
    // After loading events, refresh the calendar view
    fullCalendarApi.refetchEvents();
  });

  // Reset the countdown
  refreshCountdown.value = 15;
}

const handleMemberSelected = (selectedMember) => {
  // Handle the selected member
  console.log('Selected Member:', selectedMember);
};

const isSelectedMemberEvent = (event) => {
  if (!memberStore.selectedMember || !event.extendedProps.users) {
    return false;
  }
   
  const users = event.extendedProps.users;
  
  // If users is an array, use includes
  if (Array.isArray(users)) {
    return users.includes(memberStore.selectedMember.id);
  }
  
  // If users is a single value (e.g., a string or number), compare directly
  if (typeof users === 'string' || typeof users === 'number') {
    return users === memberStore.selectedMember.id;
  }
  
  // If users is an object, check if it has the id as a key
  if (typeof users === 'object' && users !== null) {
    return memberStore.selectedMember.id in users;
  }
  
  // If none of the above, return false
  return false;
};

const debouncedScrollToSelectedMemberEvent = debounce(() => {
  console.log('Debounced scroll to selected member event');
  scrollToSelectedMemberEvent();
}, 200);

const scrollToSelectedMemberEvent = () => {
  console.log('scrollToSelectedMemberEvent');
  if (!fullCalendar.value) return;

  const calendarApi = fullCalendar.value.getApi();

  // Find the element with the 'selected-member-event' class
  const selectedEventElement = document.querySelector('.selected-member-event');

  if (selectedEventElement) {
    // Find the time element within the selected event
    const timeElement = selectedEventElement.querySelector('.fc-event-time');

    if (timeElement) {
      // Extract the start time from the time element's text content
      const timeText = timeElement.textContent;
      const startTime = timeText.split(' - ')[0]; // This will give us "15:40" from "15:40 - 16:00"

      // Append ":00" to match the format expected by scrollToTime
      const scrollTime = `${startTime}:00`;

      console.log(`Scrolling to time: ${scrollTime}`);
      calendarApi.scrollToTime(scrollTime);
    } else {
      console.log('Time element not found within the selected event');
    }
  } else {
    console.log('No event with class "selected-member-event" found');
  }
};

onBeforeMount(() => {
  var checkIsMobile = detectMobile();
  if (checkIsMobile) {
    authStore.calendarView = "timeGridDay";
    console.log('mobile detected')
  } else {
    authStore.calendarView = "timeGridWeek";
  }
})

onMounted(() => {
  var checkIsMobile = detectMobile();
  if (checkIsMobile) {
    calendarOptions.value.headerToolbar = headerToolbarAdminMobile;
  } else {
    calendarOptions.value.headerToolbar = headerToolbarDesktop;
  }

  // if (isRefreshEnabled.value) {
  //   console.log('Refetch calendar every 15 seconds');
  // }

  window.addEventListener('resize', checkWindowSize);
  checkWindowSize();

});

onUnmounted(() => {
  if (countdownInterval.value) {
    clearInterval(countdownInterval.value);
  }
  if (fullCalendar.value) {
    fullCalendar.value.getApi().off('eventDidMount');
  }
  window.removeEventListener('resize', checkWindowSize);
})

watch(windowWidth, (newWidth) => {
  console.log('watch window width', newWidth);
  if (newWidth <= 1024 && authStore.calendarView !== "timeGridDay") {
    authStore.calendarView = "timeGridDay";
  }
})

watch(() => authStore.hideAdminMenu, () => {
  nextTick(() => {
    if (fullCalendar.value) {
      fullCalendar.value.getApi().updateSize();
    }
  });
}, { immediate: true });

// // Membersearch wordt niet meer gebruikt vanwege MemberSelect component
// watch(() => memberStore.search, (newVal) => {
//   const matchingMember = locationStore.filteredMembers.find(member =>
//     memberStore.fullName(member).toLowerCase().includes(newVal.toLowerCase())
//   );
//   if (matchingMember) {
//     memberStore.selectedMember = matchingMember;
//   }
// })

watch(() => memberStore.selectedMember, (newVal, oldVal) => {
  console.log('watcher activated on selectedMember');
  if (newVal) {
    memberStore.getSelectedMemberTimeslots();
  }
  // Refetch events to update the calendar
  if (fullCalendar.value) {
    fullCalendar.value.getApi().refetchEvents();
  }
  if (newVal?.id !== oldVal?.id) {
    nextTick(() => {
      scrollToSelectedMemberEvent();
    });
  }
})

watch(() => authStore.refreshCalendar, (newValue, oldValue) => {
  if (fullCalendar.value) {
    fullCalendar.value.getApi().refetchEvents();
  }
})

// Improved watch function
watch(
  () => route.query,
  (newQuery, oldQuery) => {
    const newWeek = newQuery?.week ? parseInt(newQuery.week, 10) : null;
    const newYear = newQuery?.year ? parseInt(newQuery.year, 10) : null;
    const oldWeek = oldQuery?.week ? parseInt(oldQuery.week, 10) : null;
    const oldYear = oldQuery?.year ? parseInt(oldQuery.year, 10) : null;

    if (newWeek !== oldWeek || newYear !== oldYear) {
      updateCalendarView(newWeek, newYear);
    }
  },
  { immediate: false, deep: true }
);
function updateCalendarView(weekNumber, yearNumber) {
  const currentDate = new Date();
  const currentYear = getCurrentYear(currentDate);
  const currentWeek = getCurrentWeek(currentDate);

  // Use provided values or fallback to current week/year
  const targetWeek = (weekNumber && weekNumber >= 1 && weekNumber <= 52) ? weekNumber : currentWeek;
  const targetYear = (yearNumber && yearNumber >= currentYear) ? yearNumber : currentYear;

  const startOfWeekDate = getFirstDayOfWeek(targetYear, targetWeek);

  nextTick(() => {
    if (fullCalendar.value) {
      console.log('updateCalendarView')
      const calendarApi = fullCalendar.value.getApi();

      // Set up a one-time loading callback
      const loadingCallback = (isLoading) => {
        if (!isLoading) {
          // Events have finished loading
          calendarApi.off('loading', loadingCallback); // Remove the callback

          if (memberStore.selectedMember) {
            debouncedScrollToSelectedMemberEvent();
          }
        }
      };

      // Add the loading callback
      calendarApi.on('loading', loadingCallback);

      // Go to the target date
      calendarApi.gotoDate(startOfWeekDate);

    } else {
      console.warn('FullCalendar is not yet initialized');
    }
  });
}

</script>

<template>
  <div>
    <div class="px-8 header">
      <h1 class="me-4">
        <span class="text-muted ms-2">{{ locationStore.selectedLocation?.name || '' }}&nbsp;</span>Kalender
      </h1>
      <MemberSelect @member-selected="handleMemberSelected" ref="memberSelect"
        v-if="authStore.user?.usertype == 'admin' || authStore.user?.usertype == 'support'" />
      <!-- <div v-if="['admin', 'support', 'trainer'].includes(authStore.user.usertype)" class="refresh-countdown">
        Next refresh in: {{ refreshCountdown }} seconds
      </div> -->
    </div>
    <div class="px-8 my-4" v-resize="onResize">
      <div class="loader-overlay" v-if="showLoader">
        <div class="loader-overlay__inner">Even geduld {{ authStore.user?.firstname }}..</div>
      </div>
      <FullCalendar class="app-calendar-admin" ref="fullCalendar" :options="calendarOptions">
        <template v-slot:eventContent='args'>

          <div class="fc-event-main-frame" :class="{ 'selected-member-event': isSelectedMemberEvent(args.event) }">
            <span class="badge bg-yellow text-black notes" v-if="args.event.extendedProps.notes == true">
              <font-awesome-icon icon="fas fa-sticky-note" class="me-2"></font-awesome-icon>
              Notitie!
            </span>
            <div class="fc-event-time fw-bold">{{ args.timeText }}</div>

            <div class="status">
              <div class="memberData"
                v-if="args.event.extendedProps.users && args.event.extendedProps.users.length > 0 && args.event.extendedProps.status !== 99">
                <div class="me-2" v-for="(user, index) in args.event.extendedProps.users" :key="index">
                  <font-awesome-icon icon="fas fa-user" class="me-2"></font-awesome-icon>
                  {{ locationStore.fetchLocationMember(user)?.fullname || 'Onbekend lid' }}

                </div>
              </div>
              <div class="memberData" v-else-if="args.event.extendedProps.status !== 99">
                <div class="me-2">
                  Niet geboekt
                </div>
              </div>
              <div class="details memberData">

                <!-- <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 1">
                  <font-awesome-icon icon="far fa-user-slash me-2"></font-awesome-icon>
                  Niet geboekt
                </span> -->
                <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 2">
                  <font-awesome-icon icon="far fa-user-group-simple"></font-awesome-icon>
                  1 Flex
                </span>
                <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 3">
                  <font-awesome-icon icon="fas fa-user-group-simple"></font-awesome-icon>
                  Duo
                </span>
                <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 4">
                  <font-awesome-icon icon="fas fa-user"></font-awesome-icon>
                  Solo
                </span>
                <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 5">
                  <font-awesome-icon icon="fas fa-user-group-simple"></font-awesome-icon>
                  2 Flex
                </span>
                <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 6">
                  <font-awesome-icon icon="fas fa-user-group-simple"></font-awesome-icon>
                  1 Flex / 1 Solo
                </span>
                <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 7">
                  KMT open
                </span>
                <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 8">
                  <font-awesome-icon icon="fas fa-user"></font-awesome-icon>
                  KMT vol
                </span>
                <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 9">
                  <font-awesome-icon icon="fas fa-user"></font-awesome-icon>
                  Instelles
                </span>
                <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 10">
                  <font-awesome-icon icon="fas fa-user-group-simple"></font-awesome-icon>
                  Instelles Duo
                </span>
                <span class="badge bg-black icon" v-if="args.event.extendedProps.status == 99">
                  Pauze
                </span>

              </div>

            </div>

          </div>
        </template>
      </FullCalendar>
    </div>
  </div>
  <Modal :id="'selectEvent'" title="" v-if="modalStore.isModalVisible('selectEvent')">
    <div>
      <div class="row g-3">
        <div class="col">
          <span class="time h5 text-black">{{ formattedStartDate(eventStore.eventInfo.event.startStr) }}</span>

          <span v-if="isAtLeast1HourAway(eventStore.eventInfo.event.startStr) === false"
            class="alert alert-danger d-block mt-3">1 uur van te voren afzeggen is verlopen. Gebruiker krijgt geen
            credit
            voor deze week.</span>
          <span class="h5 d-block" style="font-size: 12px;" v-for="(date, index) in futureDates" :key="index">
            + {{ formattedStartDate(date) }}
          </span>
          <span
            v-if="eventStore.shiftEventStatus && authStore.user && (authStore.isAdmin || authStore.user?.usertype == 'support')"
            class="alert alert-danger d-block mt-3">{{ memberStore.selectedMember.fullname }}{{
          eventStore.shiftEventAlert
        }}</span>
        </div>
      </div>
      <div class="row" v-if="authStore.userLevel > 1 && eventStore.eventInfo.members">
        <div class="col-12 mt-2" v-for="(member, index) in eventStore.eventInfo.members" :key="index">
          <span class="calMember p-2">
            <strong>{{ memberStore.fullName(member) }}</strong> <span class="usertype">{{ member.usertype }}</span><br>
            <span>{{ member.email }}</span><br>
            <div class="training-overview mt-2">
              <div class="training mb-2" v-for="(note, noteIndex) in eventStore.filteredNotes(member)" :key="noteIndex">
                <span class="date">{{ capitalizeFirstLetter(moment(note.date).format('DD-MM-YYYY HH:mm')) }} : {{
          note.message }}</span>
                <span class="actions">
                  <button @click="memberStore.removeNoteFromMember(noteIndex, member)"
                    class="btn btn-red"><font-awesome-icon icon="far fa-times" /></button>
                </span>
              </div>
              <!-- <input hidden type="text" :id="removeNote" v-model="memberStore.selectedMember.removeNotes"/> -->
            </div>

            <button v-if="authStore.user && (authStore.isAdmin || authStore.user?.usertype == 'support')" type="button"
              class="btn btn-red me-2 mt-2"
              @click="removeEventMember(eventStore.eventInfo.event, index)">Afzeggen</button>
            <button type="button" class="btn btn-success me-2 mt-2"
              @click="showEventNote(eventStore.eventInfo.event, index)"><font-awesome-icon
                icon="fas fa-note-medical" /></button>

            <div :id="'eventNote' + member.id" title="" v-if="modalStore.isModalVisible('eventNote' + member.id)"
              class="row mt-3">
              <div class="col-12">
                <textarea class="form-control" :id="'addNote'" v-model="memberStore.selectedMember.addNote"></textarea>
                <button type="button" class="btn btn-red me-2 mt-2"
                  @click="modalStore.setModalVisible('eventNote' + member.id, false)">Annuleren</button>
                <button type="button" class="btn btn-primary me-2 mt-2" @click="addEventNote()">Opslaan</button>

              </div>
            </div>

          </span>
        </div>
      </div>
      <div v-if="eventStore.eventInfo.event.extendedProps.KMT" class="col-12">
        <!-- <button type="button" class="btn btn-success me-2 mt-2" @click="showEventNote(eventStore.eventInfo.event,index)"><font-awesome-icon icon="fas fa-note-medical" /></button> -->
        <button v-if="!eventStore.showKMT" type="button" class="btn btn-success me-2 mt-2"
          @click="eventStore.showKMT = true">KMT aanmaken</button>
        <button v-if="eventStore.showKMT" type="button" class="btn btn-red me-2 mt-2"
          @click="eventStore.showKMT = false">KMT annuleren</button>
      </div>
      <div
        v-if="(eventStore.eventInfo.event.extendedProps.status == 1 || eventStore.eventInfo.event.extendedProps.status == 2) && authStore.user && !eventStore.showKMT && (authStore.isAdmin || authStore.user?.usertype == 'support')"
        class="row g-3 mt-2">
        <!-- <div class="col-12" v-if="!memberStore.selectedMember || Object.keys(memberStore.selectedMember).length === 0">
          <input class="form-control" type="search" v-model="memberStore.search" placeholder="Zoek lid..">
        </div> -->
        <div class="col-12">
          <!-- <select class="form-select" id="selectedMember" v-model="memberStore.selectedMember" > -->
          <MemberSelect @member-selected="handleMemberSelected" ref="memberSelect" />

          <!-- <select class="form-select" id="selectedMember" v-model="memberStore.selectedMember" v-if="!memberStore.selectedMember || Object.keys(memberStore.selectedMember).length === 0" >
            <option value="" disabled>Kies een member</option>
            <option v-for="(member, index) in locationStore.filteredMembers" :key="index" :value="member">
              {{ memberStore.fullName(member) }} ({{ member.usertype }})
            </option>
          </select> -->
          <!-- <span class="h5" v-if="memberStore.selectedMember && Object.keys(memberStore.selectedMember).length > 0">{{ memberStore.selectedMember.fullname }} ({{ memberStore.selectedMember.usertype }})</span> -->

          <button
            v-if="!eventStore.shiftEventStatus && authStore.user && (authStore.isAdmin || authStore.user?.usertype == 'support')"
            :disabled="memberStore.selectedMember == null || memberStore.selectedMember == '' || eventStore.eventFullyBooked"
            class="btn btn-green w-100 mt-2" @click="bookEvent">Boeken</button>
          <div
            v-if="eventStore.shiftEventStatus && authStore.user && (authStore.isAdmin || authStore.user?.usertype == 'support')">
            <p class="mt-4">Training van {{ formattedStartDate(eventStore.shiftEventMessage) }} verplaatsen?</p>
            <button
              :disabled="memberStore.selectedMember == null || memberStore.selectedMember == '' || eventStore.eventFullyBooked"
              class="btn btn-green w-50 mt-2" @click="bookShiftEvent">Verplaatsen</button>

            <button
              :disabled="memberStore.selectedMember == null || memberStore.selectedMember == '' || eventStore.eventFullyBooked"
              class="btn btn-primary mx-2 mt-2" @click="extraBookEvent">Extra Boeken</button>
          </div>

        </div>
      </div>

      <div
        v-if="(eventStore.eventInfo.event.extendedProps.KMT && eventStore.showKMT) || eventStore.eventInfo.event.extendedProps.status == 7"
        class="row g-3 mt-2">
        <div class="col-md-6">
          <div class="form-floating">
            <input type="text" class="form-control" id="kmtFirstname" placeholder="Voornaam"
              v-model="memberStore.kmt.memberInfo.firstname">
            <label for="kmtFirstname">Voornaam</label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-floating">
            <input type="text" class="form-control" id="kmtLastname" placeholder="Achternaam"
              v-model="memberStore.kmt.memberInfo.lastname">
            <label for="kmtLastname">Achternaam</label>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating">
            <input type="text" class="form-control" id="kmtEmail" placeholder="E-mailadres"
              v-model="memberStore.kmt.memberInfo.email">
            <label for="kmtEmail">E-mailadres</label>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating">
            <input type="text" class="form-control" id="kmtPhone" placeholder="Telefoonnummer"
              v-model="memberStore.kmt.memberInfo.phone">
            <label for="kmtPhone">Telefoonnummer</label>
          </div>
        </div>
        <div class="col-12">
          <!-- <button type="button" class="btn btn-success me-2 mt-2" @click="showEventNote(eventStore.eventInfo.event,index)"><font-awesome-icon icon="fas fa-note-medical" /></button> -->
          <button v-if="memberStore.kmt.showDuoForm == false" type="button" class="btn btn-success me-2 mt-2"
            @click="memberStore.kmt.showDuoForm = true">Duo toevoegen</button>
        </div>
        <div v-if="memberStore.kmt.showDuoForm" class="row g-3 mt-2">
          <div class="col-12">
            <h2>Duo partner</h2>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input type="text" class="form-control" id="kmtDuoFirstname" placeholder="Voornaam"
                v-model="memberStore.kmt.duoInfo.firstname">
              <label for="kmtDuoFirstname">Voornaam</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input type="text" class="form-control" id="kmtDuoLastname" placeholder="Achternaam"
                v-model="memberStore.kmt.duoInfo.lastname">
              <label for="kmtDuoLastname">Achternaam</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input type="text" class="form-control" id="kmtDuoEmail" placeholder="E-mailadres"
                v-model="memberStore.kmt.duoInfo.email">
              <label for="kmtDuoEmail">E-mailadres</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input type="text" class="form-control" id="kmtDuoPhone" placeholder="Telefoonnummer"
                v-model="memberStore.kmt.duoInfo.phone">
              <label for="kmtDuoPhone">Telefoonnummer</label>
            </div>
          </div>
          <div class="col-12">
            <!-- <button type="button" class="btn btn-success me-2 mt-2" @click="showEventNote(eventStore.eventInfo.event,index)"><font-awesome-icon icon="fas fa-note-medical" /></button> -->
            <button type="button" class="btn btn-red me-2 mt-2" @click="memberStore.kmt.showDuoForm = false">Duo
              Annuleren</button>
          </div>
        </div>
        <div class="col-12">
          <button :disabled="!memberStore.isKmtMemberValid" class="btn btn-green w-100 mt-2"
            @click="bookEvent">Boeken</button>
        </div>
      </div>
      <!-- <div class="row mt-2" v-if="eventStore.eventInfo.event.extendedProps.status == 1 || eventStore.eventInfo.event.extendedProps.status == 2">
          <div class="col">
            <div class="form-group mt-3" v-if="userHasCreditForWeek(getWeekFromDate(eventStore.eventInfo.event.startStr)) && eventStore.recurringCount <= 1 && !creditIsCurrentWeek">
              <span class="h5 d-block">Credit boeking gebruiken?</span>
              <label for="creditUsed" class="switch">
                <input type="checkbox" id="creditUsed" v-model="eventStore.useCredit" :disabled="creditIsCurrentWeek">
                <span class="slider round"></span>
              </label>
            </div>
            <div class="form-group mt-3" v-if="userHasCreditForWeek(getWeekFromDate(eventStore.eventInfo.event.startStr)) && eventStore.recurringCount <= 1 && creditIsCurrentWeek">
              <span class="d-block text-center alert alert-info w-100 mb-2">Credit boeking wordt gebruikt.</span>
            </div>
            <hr v-if="!eventStore.useCredit">
            <div class="form-group mt-3" v-if="!eventStore.recurringFixed && !eventStore.useCredit">
              <span class="h5 d-block">Weken vooruit boeken: {{ eventStore.recurringCount - 1 }}</span>
              <input v-model="eventStore.recurringCount" type="range" class="form-range" id="recurring" min="1" :max="maxWeekRange" :disabled="maxWeekRange <= 1">
            </div>
            
            <span class="d-block alert alert-warning" v-if="maxWeekRange <= 1">Kan niet verder vooruit boeken dan huidige boekingsweek.</span>
            <hr v-if="!eventStore.recurringFixed && !eventStore.useCredit">
            <div v-if="!eventStore.useCredit">
              <span class="h5 d-block">Automatische afspraak maken van <strong>{{ formattedRecurringDate(eventStore.eventInfo.event) }}</strong>?</span>
              <label for="recurringFixed" class="switch">
                <input type="checkbox" id="recurringFixed" v-model="eventStore.recurringFixed">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <hr> -->
    </div>
  </Modal>

</template>



<style lang='scss' scoped>
h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.fc {
  /* the calendar root */
  /* max-width: 1100px; */
  margin: 0 auto;
}

@keyframes pulse-glow {
  0% {
    box-shadow: 0 0 5px 0 rgba(30, 144, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(30, 144, 255, 0.7);
  }
  100% {
    box-shadow: 0 0 5px 0 rgba(30, 144, 255, 0.5);
  }
}

.selected-member-event {
  position: relative;
  border: 3px solid rgba(30, 144, 255, 1);
  animation: pulse-glow 2s infinite ease-in-out;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.02);
    animation: none;
    box-shadow: 0 0 25px 15px rgba(30, 144, 255, 0.8);
  }
  &:after {
    content: '\f00c';
    font-family: 'Font Awesome 6 Pro';
    font-weight: bold;
    color: white;
    font-size: 14px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 22px;
    right: -14px;
    z-index: 1;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    background: rgba(30, 144, 255, 1);
  }
}
@media (prefers-reduced-motion: reduce) {
  .selected-member-event {
    animation: none;
    box-shadow: 0 0 15px 5px rgba(30, 144, 255, 0.6);
  }
}
</style>