<script setup>
import { defineComponent, ref, onMounted, watch, onErrorCaptured } from 'vue'
import { useAuthStore, useLocationStore, useModalStore, useMemberStore, useEventStore } from '../store'

import Modal from '../components/Modal.vue'
import { basePlacements } from '@popperjs/core';
import { icon } from '@fortawesome/fontawesome-svg-core';

const authStore = useAuthStore();
const locationStore = useLocationStore();
const memberStore = useMemberStore();
const eventStore = useEventStore();
const modalStore = useModalStore();

onErrorCaptured((err, instance, info) => {
  console.error('Captured error:', err)
  console.log('Error instance:', instance)
  console.log('Error info:', info)
  return false // prevents the error from propagating further
})
// watch(
//     () => memberStore.search,
//     (newVal) => {
//       const matchingMember = locationStore.filteredMembers.find(member => 
//         memberStore.fullName(member).toLowerCase().includes(newVal.toLowerCase())
//       );
//       if (matchingMember) {
//         memberStore.selectedMember = matchingMember;
//       }
//     }
//   );

</script>
<script>
export default {
  data() {
    return {
      columns: [1, 2, 2, 2, 2, 2, 2, 2],
      excludedStatuses: [3, 4, 5],
      view: 'list',
      show: 'available',
      showicon: 'show',
      showNames: 'show',
      buttonText: 'Vastzetten'

    }
  },
  watch: {
    view(newView, oldView) {
      if (newView === 'grid') {
        if (!this.columns.includes(1)) { // Als de eerste kolom niet 1fr is, voeg deze toe
          this.columns.unshift(1);
        }
      } else if (newView === 'list') {
        if (this.columns[0] === 1) { // Als de eerste kolom 1fr is, verwijder deze
          this.columns.shift();
        }
      }
    }
  },
  computed: {

    gridColumnTemplate() {
      this.updateColumnsBasedOnOpenHours();
      return this.columns.map(col => `${col}fr`).join(' ');
    },
    dayStyle() {
      if (this.view === 'list') {
        return { display: 'block' };  // Stijl voor list weergave
      } else {
        return {
          display: 'grid',
          'grid-template-rows': `repeat(${this.numberOfRows}, 1fr)`,
          'background-size': `100% calc(100% / ${this.numberOfRows})`,
        };  // Stijl voor grid weergave
      }
    },
    numberOfRows() {
      const openHours = this.openHours;
      const startTime = this.timeToMinutes(openHours[0]);
      const endTime = this.timeToMinutes(openHours[1]);
      const durationInMinutes = endTime - startTime;

      // Aangenomen dat elk tijdslot 20 minuten duurt
      const slots = Math.ceil(durationInMinutes / 20);

      // aantal tijdslots x 2 + 2 extra rijen (verwijder x 2 als je dat niet meer nodig hebt)
      return slots * 2 + 2;
    },
    openHours() {
      const locationStore = useLocationStore();
      const openHours = locationStore.selectedLocation?.openHours.currentOpenHours;

      let earliestOpeningTime = null;
      let latestOpeningTime = null;

      openHours?.default.forEach(day => {
        if (day.open) {
          const openingTime = day.open[0];
          const closingTime = day.open[1];

          if (!earliestOpeningTime || openingTime < earliestOpeningTime) {
            earliestOpeningTime = openingTime;
          }

          if (!latestOpeningTime || closingTime > latestOpeningTime) {
            latestOpeningTime = closingTime;
          }
        }
      });
      openHours?.extra.forEach(day => {
        if (day.open) {
          const openingTime = day.open[0];
          const closingTime = day.open[1];

          if (!earliestOpeningTime || openingTime < earliestOpeningTime) {
            earliestOpeningTime = openingTime;
          }

          if (!latestOpeningTime || closingTime > latestOpeningTime) {
            latestOpeningTime = closingTime;
          }
        }
      });

      return [earliestOpeningTime, latestOpeningTime];
    },
  },
  methods: {
    updateColumnsBasedOnOpenHours() {
      if (this.view !== 'grid') {
        this.columns = [2, 2, 2, 2, 2, 2, 2];
        return;

      }
      const locationStore = useLocationStore();
      const openHours = locationStore.selectedLocation?.openHours.currentOpenHours;
      const days = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'];
      days.forEach((day, index) => {
        let d = index + 1;
        if (index == 6) {
          d = 0;
        }
        let size = 3;

        if (openHours?.extra[d]?.open) {
          size = 4;
        }

        this.columns[index + 1] = size; // Assuming the first column is always 1fr and does not change

      });
    },
    toggleView() {
      this.view = this.view == 'grid' ? 'list' : 'grid';
    },
    toggleShow() {
      this.show = this.show == 'all' ? 'available' : 'all';
    },
    toggleIcon() {
      this.showicon = this.showicon == 'show' ? 'hide' : 'show';
    },
    toggleNames() {
      this.showNames = this.showNames == 'show' ? 'hide' : 'show';
    },
    toggleColumnSize(day) {
      let index = day;
      if (this.view == 'list') {
        index = day - 1;

      }
      const locationStore = useLocationStore();
      const openHours = locationStore.selectedLocation?.openHours.currentOpenHours;
      let size = 2;
      if (openHours?.extra[day]?.open) {
        size = 3;
      }
      this.columns[index] = this.columns[index] === size ? 10 : size;
    },

    timeToMinutes(time) {
      const [hours, minutes] = time?.split(':').map(Number);
      return hours * 60 + minutes; // Converteert uur:minuten naar het totaal aantal minuten sinds middernacht
    },
    createDateWithTime(timeString) {
      const [hours, minutes] = timeString?.split(':').map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0); // Stelt uren, minuten, seconden, en milliseconden in
      return date;
    },
    getDayName(index) {
      const days = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag']
      return days[index]
    },
    hasDoubleShift(day) {
      return day?.extra && day?.extra.open
    },


    getTimeslotIndex() {
      const openHours = this.openHours;
      const earliestOpeningTime = openHours[0];
      const latestOpeningTime = openHours[1];
      const timeBlocks = [];
      const startTime = this.createDateWithTime(earliestOpeningTime);
      const endTime = this.createDateWithTime(latestOpeningTime);
      // console.log('Start Time:', startTime);
      while (startTime <= endTime) {
        const formattedTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        timeBlocks.push(formattedTime);
        startTime.setMinutes(startTime.getMinutes() + 20);
        //console.log('Start Time:', startTime);
      }

      // console.log('Time Blocks:', timeBlocks);
      return timeBlocks;
    },
    bookRecurring() {
      const eventStore = useEventStore();
      const memberStore = useMemberStore();
      const job = eventStore.recurringShift ? 'shift' : 'add';
      eventStore.updateRecurring(job,false);
      
      
    },
    removeRecurring(id) {
      const eventStore = useEventStore();
      eventStore.updateRecurring('remove',id);


    },
    getTimeslots(day) {
      const eventStore = useEventStore();
      const weekday = day;
      if (day == 6) {
        day = 0;

      } else {
        day = day + 1;
      }
      if (day == 1) {
        eventStore.recurringShift = false;
        this.buttonText = 'Vastzetten';
      }
      const locationStore = useLocationStore();

      // const memberStore = useMemberStore();
      const openHours = locationStore.selectedLocation?.openHours.currentOpenHours;
      const recurring = locationStore.selectedLocation?.recurring || {};
      //console.log('recurring: ' + recurring);
      const [earliestOpeningTime, latestOpeningTime] = this.openHours;
      const timeBlocks = [];
      if(openHours?.default[day].open == false){
        return timeBlocks;
      }

      let slotIndex = 1;
      let shift = 1;

      let shifts = 1;
      let shiftType = 'default';
      if (openHours?.extra[day].open) {
        shifts = 2;
      }

      //console.log('open : ' + openHours.extra[day].open);
      while (shift <= shifts) {
        // Set up times for each shift

        let currentTime = this.createDateWithTime(earliestOpeningTime);

        let startTime = this.createDateWithTime(openHours?.default[day].open[0]);
        let endTime = this.createDateWithTime(openHours?.default[day].open[1]);

        if (shift == 2 && openHours?.extra[day].open) {
          // Adjust times for shift 2

          slotIndex = 0.5;
          shiftType = 'extra';
          currentTime.setMinutes(currentTime.getMinutes() - 10);
          startTime = this.createDateWithTime(openHours?.extra[day].open[0]);
          endTime = this.createDateWithTime(openHours?.extra[day].open[1]);
        }

        // Process each time slot within the current shift
        while (currentTime < endTime) {
          if (currentTime < startTime) {
            slotIndex++;
            currentTime.setMinutes(currentTime.getMinutes() + 20);
            continue;
          }
          if (this.isDuringClose(currentTime, day, shiftType)) {
            slotIndex++;
            currentTime.setMinutes(currentTime.getMinutes() + 20);
            continue;
          }

          const slotEndTime = new Date(currentTime.getTime() + 20 * 60 * 1000); // 20 minutes later
          if (this.isDuringClose(slotEndTime)) {
            currentTime = this.getNextStartTime(slotEndTime);
            continue;
          }

          const formattedStartTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }).slice(0, 5);
          const formattedEndTime = slotEndTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }).slice(0, 5);

          const startClass = 2 * slotIndex + 1;
          const endClass = startClass + 2;
          let style = `grid-row-start: ${startClass}; grid-row-end: ${endClass};`;
          if (shifts == 2) {
            style += `grid-column-start: ${shift};grid-column-end: ${shift};`;
          }

          let status = 1;
          let users = [];
          let id = '';
          let usersDetails = [];
          const timeKey = `${formattedStartTime}:00`;
          // Use optional chaining to safely access nested properties


          const recDay = day.toString();
          const slot = recurring[recDay]?.[timeKey] || false;
          if (slot) {
            id = slot.id || '';
            users = slot.user_ids || [];
            status = slot.status || 1;
            // console.log('Before fetch:    ', users);
            usersDetails = locationStore.fetchLocationMembersByIDs(users) || [];
          }

          const memberStore = useMemberStore();
          let opacityClass = '';
          if ((this.excludedStatuses.includes(status) && this.show == 'available')) {
            if (memberStore.selectedMember?.id && users.includes(memberStore.selectedMember?.id)) {

            } else {
              style = 'display: none;';
            }
          }
          if (this.show == 'available' && status !== 1) {
            if (memberStore.selectedMember?.id && users.includes(memberStore.selectedMember?.id)) {

            } else if (memberStore.selectedMember?.usertype && (memberStore.selectedMember?.usertype == 'duo' || memberStore.selectedMember?.usertype == 'solo')) {
              style = 'display: none;';
            }

          }
          if (memberStore.selectedMember?.id && users.includes(memberStore.selectedMember?.id)) {
            opacityClass += ' opacity--high';
            eventStore.recurringShift = true;
            this.buttonText = 'Verplaatsen';
          }
          if (memberStore.selectedMember?.id && !users.includes(memberStore.selectedMember?.id)) {
            if ((status == 2 && memberStore.selectedMember?.usertype !== 'duo' && memberStore.selectedMember?.usertype !== 'solo') || status == 1) {
              opacityClass += ' opacity--mid';
            } else {
              opacityClass += ' opacity--low';

            }
          }
          timeBlocks.push({
            id: id,
            start: formattedStartTime,
            end: formattedEndTime,
            day: day,
            weekDay: this.getDayName(weekday),
            style: style,
            members: usersDetails,
            status: status,
            class: 'status--' + status + opacityClass
          });

          currentTime.setMinutes(currentTime.getMinutes() + 20);
          slotIndex++;
        }

        shift++;
        // Reset slot index for each shift to align grid rows correctly per shift
      }
      timeBlocks.sort((a, b) => {
        const timeA = a.start.split(':').map(Number); // Splits de tijd in uren en minuten en zet ze om naar getallen
        const timeB = b.start.split(':').map(Number);
        return timeA[0] - timeB[0] || timeA[1] - timeB[1]; // Eerst vergelijken op uur, dan op minuut
      });

      //console.log('Time Blocks:', timeBlocks);
      
      return timeBlocks;
    },

    isDuringClose(time, day, shiftType) {
      const locationStore = useLocationStore();
      const openHours = locationStore.selectedLocation?.openHours.currentOpenHours;
      let closed = false;

      // Accessing shift-specific data dynamically
      const shiftData = openHours[shiftType] && openHours[shiftType][day];

      if (shiftData && shiftData.closed) {
        shiftData.closed.forEach(slot => {
          if (slot) {
            const closeStart = this.createDateWithTime(slot[0]);
            const closeEnd = this.createDateWithTime(slot[1]); // This should likely be slot[1] instead of slot[0] again
            if (time >= closeStart && time < closeEnd) {
              closed = true;
            }
          }
        });
      }

      return closed;
    },

    getNextStartTime(currentTime) {
      for (let pause of this.pauses) {
        const pauseStart = this.createDateWithTime(pause.start);
        const pauseEnd = this.createDateWithTime(pause.end);
        if (currentTime >= pauseStart && currentTime < pauseEnd) {
          return new Date(pauseEnd);
        }
      }
      return currentTime;
    }//,
    // async getLocationRecurring() {
    //   const locationStore = useLocationStore();
    //   return locationStore.fetchLocationRecurring();
    // }

  }
}

</script>





<template>
  <div class="px-8 header">
    <h1>
      <span class="text-muted ms-2">{{ locationStore.selectedLocation?.name }}&nbsp;</span>
      Vaste traingingen
    </h1>
  </div>
  <div class="view-settings py-2 px-8">
    <strong><span class="text-muted ms-2">Weergave: </span></strong>
    <button @click="toggleView" class="btn btn-sm me-2 btn-primary ms-2">{{ view == 'grid' ? 'lijst'
        : 'grid' }}</button><button @click="toggleShow" class="btn btn-sm me-2 btn-primary ms-2">{{ show == 'all' ?
        'Beschikbare' : 'Alle' }} tijdsloten</button><button @click="toggleIcon"
      class="btn btn-sm me-2 btn-primary ms-2">{{ showicon == 'show' ? 'Verberg' : 'Toon' }} lidmaatschap</button>
    <button @click="toggleNames" class="btn btn-sm me-2 btn-primary ms-2">{{ showNames == 'show' ? 'Verberg' : 'Toon' }}
      namen</button>
  </div>
  <div v-if="locationStore.selectedLocation">
    <div class="schedule" :style="{ 'grid-template-columns': gridColumnTemplate }">

      <div v-if="view == 'grid'" :style="{ 'grid-template-rows': `repeat(${numberOfRows}, 1fr)` }"
        class="schedule__times">
        <div class="schedule__time"></div>
        <div class="schedule__time" v-for="(time, index) in getTimeslotIndex()">{{ time }}</div>
      </div>
      <div :style="dayStyle" class="schedule__day"
        v-for="(day, index) in ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag']">

        <div class="schedule__header" @click="toggleColumnSize(index + 1)">
          <span class="h5">{{ getDayName(index) }}</span>
        </div>
        <div class="schedule__timeslot" v-for="(timeslot, timeIndex) in getTimeslots(index)" :key="timeIndex"
          :data-start="timeslot.start" :data-end="timeslot.end" :data-day="timeslot.day" :data-status="timeslot.status"
          :style="timeslot.style" :class="timeslot.class" @click="eventStore.selectTimeslot(timeslot, true)">
          <span class="mt-1">{{ timeslot.start }} - {{ timeslot.end }}</span>
          <div class="status text-center">
            <div class="memberData "
              v-if="showNames == 'show' && timeslot.members && Array.isArray(timeslot.members) && timeslot.members.length > 0 && timeslot.status !== 99">
              <div class="me-2" v-for="(user, index) in timeslot.members" :key="index">
                <font-awesome-icon icon="fas fa-user" class="me-2"></font-awesome-icon>
                {{ locationStore.fetchLocationMember(user.id)?.fullname || 'Onbekend lid' }}

              </div>
            </div>
            <div class="memberData" v-else-if="showNames == 'show' && timeslot.status !== 99">
              <div class="me-2">
                Niet geboekt
              </div>
            </div>
            <div class="details memberData" v-if="showicon == 'show'">

              <!-- <span class="badge bg-black icon" v-if="timeslot.status == 1">
              <font-awesome-icon icon="far fa-user-slash me-2"></font-awesome-icon>
              Niet geboekt
            </span> -->
              <span class="badge bg-black icon" v-if="timeslot.status == 2">
                <font-awesome-icon icon="far fa-user-group-simple"></font-awesome-icon>
                1 Flex
              </span>
              <span class="badge bg-black icon" v-if="timeslot.status == 3">
                <font-awesome-icon icon="fas fa-user-group-simple"></font-awesome-icon>
                Duo
              </span>
              <span class="badge bg-black icon" v-if="timeslot.status == 4">
                <font-awesome-icon icon="fas fa-user"></font-awesome-icon>
                Solo
              </span>
              <span class="badge bg-black icon" v-if="timeslot.status == 5">
                <font-awesome-icon icon="fas fa-user-group-simple"></font-awesome-icon>
                2 Flex
              </span>
              <span class="badge bg-black icon" v-if="timeslot.status == 6">
                <font-awesome-icon icon="fas fa-user-group-simple"></font-awesome-icon>
                1 Flex / 1 Solo
              </span>
              <span class="badge bg-black icon" v-if="timeslot.status == 7">
                KMT open
              </span>
              <span class="badge bg-black icon" v-if="timeslot.status == 8">
                <font-awesome-icon icon="fas fa-user"></font-awesome-icon>
                KMT vol
              </span>
              <span class="badge bg-black icon" v-if="timeslot.status == 9">
                <font-awesome-icon icon="fas fa-user"></font-awesome-icon>
                Instelles
              </span>
              <span class="badge bg-black icon" v-if="timeslot.status == 10">
                <font-awesome-icon icon="fas fa-user-group-simple"></font-awesome-icon>
                Instelles Duo
              </span>
              <span class="badge bg-black icon" v-if="timeslot.status == 99">
                Pauze
              </span>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
  <Modal :id="'selectTimeslot'" title="" v-if="modalStore.isModalVisible('selectTimeslot')">
    <div class="row g-3">
      <div class="col">
        <span class="time h5 text-black">{{ eventStore.selectedTimeslot.weekDay }} om {{
        eventStore.selectedTimeslot.start }}-{{ eventStore.selectedTimeslot.end }}</span>
        <div class="row" v-if="authStore.userLevel > 1 && eventStore.selectedTimeslot.members">
          <div class="col-12 mt-2" v-for="(member, index) in eventStore.selectedTimeslot.members" :key="index">
            <span class="calMember p-2">
              <strong>{{ memberStore.fullName(member) }}</strong> <span class="usertype">{{ member.usertype
                }}</span><br>
              <span>{{ member.email }}</span><br>
              <button v-if="authStore.user && (authStore.isAdmin || authStore.user?.usertype == 'support')"
                type="button" class="btn btn-red me-2 mt-2" @click="removeRecurring(member.id)">Verwijderen</button>
            </span>
          </div>
        </div>
        <div
          v-if="(eventStore.selectedTimeslot.status == 1 || eventStore.selectedTimeslot.status == 2) && (authStore.isAdmin || authStore.user?.usertype == 'support')"
          class="row g-3 mt-2">
          <div class="col-12">
            <input class="form-control" type="search" v-model="memberStore.search" placeholder="Zoek lid..">
          </div>
          <div class="col-12">
            <select class="form-select" id="selectedMember" v-model="memberStore.selectedMember">
              <option value="" disabled>Kies een member</option>
              <option v-for="(member, index) in locationStore.filteredMembers" :key="index" :value="member">
                {{ memberStore.fullName(member) }} ({{ member.usertype }})
              </option>
            </select>

            <button
              v-if="((authStore.isAdmin || authStore.user?.usertype == 'support') || (memberStore.selectedMember !== null || memberStore.selectedMember !== ''))"
              :disabled="memberStore.selectedMember == null || memberStore.selectedMember == '' || eventStore.recurringAvailable"
              class="btn btn-green w-100 mt-2" @click="bookRecurring">{{ buttonText }}</button>


          </div>
        </div>

      </div>
    </div>

  </Modal>

</template>

<style></style>